<template>
    <div class="container">
        <div class="content">
			<div id="main" style="margin-left:20px;width:650px;height:400px;"></div>
		    <Divider plain orientation="left" v-show="!loading2">当前展示为回款率【{{xAxis[activeIndex]}}】的项目列表</Divider>
            <Table stripe :loading="loading2" :columns="columns2" :data="tempArr" height="300"></Table>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            columns2: [
                {
                    title: '序号',
                    type: 'index',
                    align: 'center',
                },
                {
                    title: '项目名称',
                    key: 'projectName',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '业委会',
                    key: 'committee',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '合同金额(万元)',
                    key: 'contractAmount',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '开票金额(万元)',
                    key: 'invoicedAmount',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '回款金额(万元)',
                    key: 'collectionAmount',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '回款率',
                    key: 'collectionRate',
                    align: 'center',
                    tooltip: true
                }
            ],
            data2:[],
            pageNum2:1,
            loading2:true,
            tempArr:[],
            myChart:null,
            rateItemGroup:[],
            activeIndex:0,
            xAxis:["<1%","1%~50%","50%~70%","70%~98%",">=98%","未统计"]
        }
    },

    methods:{
        initData(){
            this.$http.get(`/dashboard/hklList`).then(rs=>{
				let a=0,b=0,c=0,d=0,e=0,f=0
				rs.forEach(item => {
					//回款率统计 用于echart数据渲染
					switch(true){ //不能写switch(rate)，因为表达式值为bool类型
						case item.rateNum==null&&item.contractAmount==0:
							f++;
							break;
						case item.rateNum<1:
							a++;
							break;
						case 1<=item.rateNum&&item.rateNum<50:
							b++
							break;
						case 50<=item.rateNum&&item.rateNum<70:
							c++
							break;
						case 70<=item.rateNum&&item.rateNum<98:
							d++
							break;
						case item.rateNum>=98:
							e++
							break;
						default:
					}
				});

				//echarts赋值
				this.rateItemGroup = [a,b,c,d,e,f];
				this.myChart.setOption({
					series:[ { data: this.rateItemGroup } ]
				});
				this.myChart.hideLoading();

                this.data2 = this.data2.concat(rs);
                this.tempArr = this.filterItemArr(this.data2);
                this.loading2=false;
            })
        },

        filterItemArr(arr){
            let newArr=[];
            switch (this.activeIndex){
                case 0:
                    newArr = arr.filter((item) => {
                        return item.contractAmount>0 && item.rateNum<1
                    });
                    break;
                case 1:
                    newArr = arr.filter((item) => {
                        return 1<=item.rateNum&&item.rateNum<50
                    });
                    break;
                case 2:
                    newArr = arr.filter((item) => {
                        return 50<=item.rateNum&&item.rateNum<70
                    });
                    break;
                case 3:
                    newArr = arr.filter((item) => {
                        return 70<=item.rateNum&&item.rateNum<98
                    });
                    break;
                case 4:
                    newArr = arr.filter((item) => {
                        return item.rateNum>=98
                    });
                    break;
                case 5:
                    newArr = arr.filter((item) => {
                        return item.contractAmount==0 && item.rateNum==null
                    });
                    break;
                default:
                    newArr=arr;
            }
            return newArr;
        },

        initChart(){
			// 基于准备好的dom，初始化echarts实例
			this.myChart = this.$echarts.init(document.getElementById('main'));
			// 指定图表的配置项和数据
			var option = {
				title: {
					text: '项目回款率',
					textStyle: {
						fontSize: 14,
					},
					left:20
				},
				tooltip: {},
				legend: {
					data:['项目数量']
				},
				xAxis: {
					data: this.xAxis
				},
				yAxis: {},
				series: [{
					name: '项目数量',
					type: 'bar',
					data: []
				}]
			};

			// 使用刚指定的配置项和数据显示图表。
			this.myChart.setOption(option);
			// 处理点击事件并且跳转到相应的百度搜索页面
			this.myChart.on('click',  (params)=>{
                this.loading2=true
                this.activeIndex = params.dataIndex
                this.tempArr = this.filterItemArr(this.data2)
                setTimeout(()=>{
                    this.loading2=false
                },1000)
			});
		}
	},

	mounted(){
        if(this.$route.params.dataIndex){
            this.activeIndex=this.$route.params.dataIndex;
        }
        this.initData();
		//初始化图表
		this.initChart();
        // console.log(this.myChart.getOption());
        this.myChart.showLoading();
	}
}
</script>

<style scoped>
.container{
    width: 100%;
    margin: 0 auto;
    padding: 24px 15px; 
    min-height: calc(100vh - 160px);
    background: #fff;
}
</style>